<template>
    <content-view>
        <structure-view/>
    </content-view>
</template>

<script>
import { metaTitle } from '@/mixins/meta_title'

export default {
    name: 'Structure',
    mixins: [metaTitle],
    components: {
        ContentView : () => import('@/views/menu/ContentView'),
        StructureView : () => import('@/components/structure/Structure')
    }
}
</script>

<style scoped>

</style>
